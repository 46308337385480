// datepicker
$(".pardotDatepicker").each(function () {
	var disabledDays = $(this).attr("data-custom-disabled-days");
	var dayAfterTomorrow = $(this).attr("data-day-after-tomorrow");

	$(this).datepicker({
		autoClose: true,
		minDate: new Date(), // Now can select only dates, which goes after today

		onRenderCell: function (date, cellType) {
			if (cellType == "day" && disabledDays !== undefined) {
				var selectedDate =
					date.getFullYear() +
					"-" +
					(date.getMonth() + 1) +
					"-" +
					date.getDate();

				var disabled_days = disabledDays.indexOf(date.getDay()) != -1;

				var d = new Date();
				var todaysDate =
					d.getFullYear() +
					"-" +
					(d.getMonth() + 1) +
					"-" +
					d.getDate();
				var tomorrowsDate =
					d.getFullYear() +
					"-" +
					(d.getMonth() + 1) +
					"-" +
					(d.getDate() + 1);

				var disable_today = todaysDate.indexOf(selectedDate) != -1;
				var disable_tomorrow =
					tomorrowsDate.indexOf(selectedDate) != -1;

				if (dayAfterTomorrow == "disable") {
					return {
						disabled:
							disable_tomorrow + disable_today + disabled_days,
					};
				} else {
					return { disabled: disable_today + disabled_days };
				}
			}
		},
	});

	function getFormattedDate(date) {
		var year = date.getFullYear(),
			month = date.getMonth() + 1,
			date = date.getDate();

		return year + "." + month + "." + date;
	}
});

$("#search-input").change(function () {
	var action = $(this).val();
	$("#search-form").attr(
		"action",
		globalVars.siteURL + "/" + globalVars.language + "/search/" + action
	);
});

$(".lazy").show().lazy();

$(".lazyFadeIn").show().lazy({
	effect: "fadeIn",
	effectTime: 500,
	threshold: 250,
});

$(".google-sheets-form").on("submit", function (e) {
	e.preventDefault();

	const form = $(this);
	const actionURL = $(this).attr("data-action-url");
	const thankYou = $(this).attr("data-thank-you");

	if (form.valid()) {
		form.find('button[type="submit"]').attr("disabled", "disabled");

		// road to creating json string 🛣
		var gsInfo = {};

		// grabs each field
		$(this)
			.find(".json-pick")
			.each(function () {
				field = $(this);

				if (field.hasClass("checkbox")) {
					var name = $(this).find("input").attr("name");
					var groupValue = [];
					$(this)
						.find('.checkbox input[type="checkbox"]:checked')
						.each(function () {
							var value = $(this).val();
							groupValue.push(value);
						});
					var value = JSON.stringify(groupValue);
				} else if (field.hasClass("radio")) {
					var name = $(this).find("input").attr("name");
					var value = $(this).find("input:checked").val();
				} else if (field.hasClass("select")) {
					var name = $(this).find("select").attr("name");
					var value = $(this).find("option:selected").val();
				} else {
					var name = field.attr("name");
					var value = field.val();
				}

				// only adds to json if value exists
				if (value) {
					gsInfo[name] = value;
				}
			});

		// converts JS array to JSON string
		var jsonOutput = JSON.stringify(gsInfo);

		var currentURL = window.location.href;

		var googleSheetSend = $.ajax({
			url: actionURL,
			method: "GET",
			dataType: "json",
			data: gsInfo,
		}).success(
			setTimeout(function () {
				if (currentURL.indexOf("backbone") >= 0) {
					analytics.track("Lead Created", {
						name: "pro_pictures_order_form",
						category: "Backbone Partnership",
					});
				}

				window.location.href = thankYou;
			}, 250)
		);
	}
});

$(".default-card-carousel").each(function () {
	var showDots = $(this).attr("data-dots");
	var number = $(this).attr("data-counter");

	if (showDots !== undefined) {
		var dots = true;

		if ($(this).attr("data-counter") < 4) {
			var dots = false;
		}
	} else {
		var dots = false;
	}

	if (number <= 3) {
		var mosueDrag = false;
		var desktopNav = false;
	} else {
		var mouseDrag = true;
		var dekstopNav = true;
	}

	$(this).owlCarousel({
		items: 3,
		lazyLoad: true,
		loop: true,
		margin: 0,
		mouseDrag: mosueDrag,
		autoplay: false,
		autoplayTimeout: 5000,
		autoplaySpeed: 1000,
		autoplayHoverPause: true,
		nav: dekstopNav,
		navSpeed: 1000,
		stagePadding: 0,
		dots: dots,
		navText: [
			'<button class="circle-btn"><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16"><path fill="#008799" fill-rule="evenodd" d="M3.28643832,7.10780957 L14.2150751,7.10780957 C14.4912175,7.10780957 14.7150751,7.3316672 14.7150751,7.60780957 C14.7150751,7.88395195 14.4912175,8.10780957 14.2150751,8.10780957 L3.19481716,8.10780957 L7.1610825,12.2629447 C7.35175211,12.4626938 7.34439172,12.7791907 7.1446426,12.9698603 C6.94489348,13.1605299 6.62839657,13.1531696 6.43772696,12.9534204 L1.38090899,7.65580161 L6.43772696,2.35818278 C6.62839657,2.15843366 6.94489348,2.15107327 7.1446426,2.34174288 C7.34439172,2.5324125 7.35175211,2.84890941 7.1610825,3.04865853 L3.28643832,7.10780957 Z"/></svg></button>',
			'<button class="circle-btn"><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16"><path fill="#008799" fill-rule="evenodd" d="M12.8095457,8.20379364 L1.88090899,8.20379364 C1.60476661,8.20379364 1.38090899,7.97993602 1.38090899,7.70379364 C1.38090899,7.42765127 1.60476661,7.20379364 1.88090899,7.20379364 L12.9011669,7.20379364 L8.93490157,3.04865853 C8.74423196,2.84890941 8.75159235,2.5324125 8.95134147,2.34174288 C9.15109059,2.15107327 9.4675875,2.15843366 9.65825712,2.35818278 L14.7150751,7.65580161 L9.65825712,12.9534204 C9.4675875,13.1531696 9.15109059,13.1605299 8.95134147,12.9698603 C8.75159235,12.7791907 8.74423196,12.4626938 8.93490157,12.2629447 L12.8095457,8.20379364 Z"/></svg></button>',
		],
		responsive: {
			0: {
				margin: 1,
				items: 1,
				nav: false,
				dots: true,
				mouseDrag: true,
			},
			767: {
				items: 2,
				nav: true,
				dots: true,
				mouseDrag: true,
			},
			1000: {
				items: 3,
			},
		},
	});
});

// START: custom lazy loading for picture tag

function customLazyLoading(pos) {
	$(".lazyPicture").each(function () {
		var picture = $(this);
		var picPosition = picture.offset().top;
		var topOfWindow = $(window).scrollTop();

		if (picPosition < topOfWindow + $(window).height()) {
			$(picture)
				.children("source")
				.each(function () {
					var url = $(this).attr("data-srcset");
					$(this).attr("srcset", url).removeAttr("data-srcset");
				});

			$(picture)
				.children("img")
				.each(function () {
					var url = $(this).attr("data-src");
					$(this).attr("src", url).removeAttr("data-src");
				});
		}
	});
}

$(document).ready(customLazyLoading);
$(window).scroll(customLazyLoading);

// END: custom lazy loading for picture tag

// Forms Floaty Label
$("body").on(
	"focusin",
	".ch-custom-input input, .ch-custom-input textarea",
	function () {
		const input = $(this);
		const parent = input.parent();

		parent.addClass("float");
	}
);

$("body").on(
	"focusout",
	".ch-custom-input input, .ch-custom-input textarea",
	function () {
		const input = $(this);
		const parent = input.parent();

		setTimeout(function () {
			if (!input.val()) {
				parent.removeClass("float");
			}
		}, 150);
	}
);

// Country select popup trigger
$(document).ready(function () {
	$.get(
		"https://ipinfo.io",
		function (response) {
			userCountryCode = response.country.toLowerCase();

			// if visited site and user location do not match
			// then look for other options
			if (globalVars.currentSite != userCountryCode) {
				$.ajax({
					url: country_notice_popup_ajax_obj.ajaxurl,
					data: {
						action: "ajax_country_notice_popup",
						userCountryCode: userCountryCode,
						userCountryName: response.region,
					},
					success: function (data) {
						// don't run if no data
						if (!data) return;

						$("body").prepend(data);

						(element = $("#ch-popup-country-warning")),
							(timeout = parseInt(element.attr("data-timeout"))),
							(frequency = parseInt(
								element.attr("data-frequency")
							)),
							(id = element.attr("id")),
							(cookie = Cookies.get(id));

						// Do not show popup if any type of cookie exists
						if (cookie == undefined) {
							setTimeout(function () {
								element
									.fadeIn()
									.find(".popup-window")
									.addClass("active");

								// set cookie based on admin input on how often it shoud appear
								Cookies.set(id, "open", { expires: frequency });
							}, timeout);
						}
					},
					error: function (errorThrown) {
						console.log(errorThrown);
					},
				});
			}
		},
		"jsonp"
	);
});

$(document).ready(function () {
	if ($("#form_on_mobile").length > 0) {
		var formWrapper = $(".ch-form-wrapper");
		var originalParent = formWrapper.parent();

		function moveForm() {
			if ($(window).width() < 960) {
				$("#form_on_mobile").append(formWrapper);
			} else {
				originalParent.append(formWrapper);
			}
		}

		// Initial check
		moveForm();

		// Check on window resize
		$(window).on("resize", function () {
			moveForm();
		});
	}
});
